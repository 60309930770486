<section class="connect bg-light">
  <div
    class="main-container"
    [ngClass]="{ 'pb-300': router.url === '/about-us' }"
  >
    <div id="connect">
      <div class="connect__part">
        <div class="main-title text-center">
          <h1 class="large">
            {{ contactSection()?.title }}
          </h1>
          <h5>
            {{ contactSection()?.description }}
          </h5>
        </div>

        <ul>
          @for (contact of contactSection()?.contacts; track contact.title) {
            @if ($index <= 1) {
              <li class="contact__bg-hover text-center">
                <span
                  ><img [src]="contact.image.url" [alt]="contact.image.alt"
                /></span>
                <h2>{{ contact.title }}</h2>
                <h5 class="text-light">
                  {{ contact.description }}
                </h5>
              </li>
            }
          }
        </ul>

        <ng-container>
          <ul class="social">
            <li>
              <a
                href="{{ socialSection()?.links.in.url }}"
                target="{{ socialSection()?.links.in.target }}"
                title="Linkedin"
                ><span class="icon fa fa-linkedin"></span
              ></a>
            </li>
            <li>
              <a
                href="{{ socialSection()?.links.tw.url }}"
                target="{{ socialSection()?.links.tw.target }}"
                title="Twitter"
                ><span class="icon fa-brands fa fa-x-twitter"></span
              ></a>
            </li>
            <li>
              <a
                href="{{ socialSection()?.links.fb.url }}"
                target="{{ socialSection()?.links.fb.target }}"
                title="Facebook"
                ><span class="icon fa fa-facebook"></span
              ></a>
            </li>
          </ul>
        </ng-container>
      </div>
      <div class="connect__part" #getInTouch>
        <div class="contact__box text-center">
          <app-connect-form></app-connect-form>
        </div>
      </div>
    </div>
  </div>
</section>
