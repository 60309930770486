import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  effect,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IContactSection, ISocialSection } from 'shared/models';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ConnectFormComponent } from '../connect-form/connect-form.component';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ConnectFormComponent],
})
export class ContactFormComponent {
  getInTouchRef = output<HTMLElement>();
  contactSection = input.required<IContactSection>();
  getInTouch = viewChild<ElementRef<HTMLDivElement>>('getInTouch');
  socialSection = signal<ISocialSection>(null);
  private subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    sharedService: SharedService,
  ) {
    effect(() => {
      if (this.getInTouch()) {
        this.getInTouchRef.emit(this.getInTouch().nativeElement);
      }
    });

    this.subscriptions.push(
      sharedService
        .getSocialSection()
        .pipe(
          tap((links) => {
            if (links) {
              this.socialSection.set(links);
            }
          }),
        )
        .subscribe(),
    );
  }
}
